import React from "react"
import Layout from "../../components/layout"
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import {useTheme} from '@material-ui/core/styles';
// import SmallPage from "../components/PageComponents/SmallPage.js"
// import IntroPage from "../components/PageComponents/IntroPage.js"
// import EnterNamePage from "../components/PageComponents/EnterNamePage.js"
// import SelectFontTypePage from "../components/PageComponents/SelectFontTypePage.js"
// import SelectFontPage from "../components/PageComponents/SelectFontPage.js"
// import SearchFontPage from "../components/PageComponents/SearchFontPage.js"
// import IntroColorPage from "../components/PageComponents/IntroColorPage.js"
// import SelectPalettePage from "../components/PageComponents/SelectPalettePage.js"
// import EditPalettePage from "../components/PageComponents/EditPalettePage.js"
// import CompletePage from "../components/PageComponents/CompletePage.js"
import MobileCompletePage from "../../components/PageComponents/MobileCompletePage.js"
import WebFont from 'webfontloader';

if (typeof window !== `undefined`) {
  WebFont.load({
    google: {
      families: ['Work Sans:lighter,normal,bold,bolder', 'Lobster:lighter,normal,bold,bolder']
    }
  });
}

function IndexPage() {
  // const theme = useTheme();
  // const [page, setPage] = useState('IntroPage')
  // const [selectedBrandName, setSelectedBrandName] = useState(null)
  // const [selectedFontType, setSelectedFontType] = useState(null)
  // const [selectedFont, setSelectedFont] = useState(null)
  // const [selectedPalette, setSelectedPalette] = useState(['#EDEDED'])

  let renderedPage

  // let isXsDown = (useMediaQuery(theme.breakpoints.down('xs')))

  renderedPage = <MobileCompletePage selectedPalette={["#e8ddcb", "#cdb380", "#036564", "#033649", "#031634"]} selectedBrandName='QuickStyle' selectedFont='Lobster'/>

  return (<Layout>
    {renderedPage}
  </Layout>)
}

export default IndexPage
